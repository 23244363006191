const Images = {
  logo: require("./logo_blue.svg").default,
  thingBg: require("./thingsBg.svg").default,
  myImage: require("./vinod_img.webp").default,
  model_3d: require("./3d_model.webp").default,
  codeSnap: require("./code_snap.webp").default,
  pcb: require("./pcb.webp").default,
};

module.exports = Images;
