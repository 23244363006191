import React, { useEffect } from "react";
import Images from "../../assets/images/images";
import ProjectCard from "../common/ProjectCard/ProjectCard";
// import {
//   promotedProjects,
//   projectList,
// } from "../../assets/projects/projectsData";
import { Outlet } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useState } from "react";
import axios from "axios";

const ProjectsPage = () => {
  const [projectList, setProjectList] = useState([]);
  const [promotedProjectsData, setPromotedProjectsData] = useState([]);
  mixpanel.init("a12ca9a99d7c4a1c7308e83c538581a6");

  // console.log(promotedProjects);
  // var promotedProjectsData = [];
  // promotedProjects.forEach((id) => {
  //   projectList.forEach((project) => {
  //     if (id === project.pid) {
  //       promotedProjectsData.push(project);
  //     }
  //   });
  // });
  // const promotedProjectsData = projectList.filter((project) =>
  //   promotedProjects.includes(project.pid)
  // );
  // console.log(promotedProjectsData);
  useEffect(() => {
    document.title = "Maker Vinod - Makes";
    window.scrollTo(0, 0);
    mixpanel.track("Makes Page Views");

    axios.get("/projects/all").then((response) => {
      var allProjectsData = response.data;

      allProjectsData.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1));
      setProjectList(allProjectsData);
      const featuredProjects = allProjectsData.filter(
        (project) =>
          (project.featured !== null || project.featured !== undefined) &&
          project.featured !== 0
      );
      if (featuredProjects.length > 0) {
        featuredProjects.sort((a, b) => (a.featured > b.featured ? 1 : -1));
        setPromotedProjectsData(featuredProjects);
      }
    });
  }, []);

  return (
    <div className="ProjectsPage pageContainer">
      {projectList.length > 0 && (
        <div className="ProjectsPage__content pageContent">
          <div className="ProjectsPage__thingsPreview">
            <div className="thingsBannerBg">
              <div
                className="artwork"
                style={{
                  background: `url(${Images.thingBg})`,
                }}
              ></div>
            </div>
            <div className="content">
              <h1 className="heading">Makes</h1>
              <div className="projectsPreview">
                {promotedProjectsData.map((projectData, key) => {
                  // console.log(projectData);
                  if (key < 3) {
                    return <ProjectCard key={key} data={projectData} />;
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
          <div className="ProjectsPage__allProjects">
            <div className="headContent">
              <h1 className="heading">All Projects</h1>
            </div>
            <div className="projectsContent">
              <div className="projectsList">
                {projectList.map((projectData, key) => {
                  // console.log(projectData);
                  return (
                    <ProjectCard key={key} small={true} data={projectData} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default ProjectsPage;
