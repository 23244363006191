import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../../assets/images/images";

const ProjectCard = (props) => {
  var months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let navigate = useNavigate();
  const cardClass = props.small ? "projectCard smallCard" : "projectCard";
  const openProject = (pid) => {
    // console.log(pid);
    navigate("/makes/" + pid);
  };
  const [imageLoaded, setImageLoaded] = useState(false);
  // console.log(props.data.categories[0]);

  // var projectMonth=
  var projectMonth = months[new Date(props.data.dateCreated).getMonth()];
  var projectYear = new Date(props.data.dateCreated).getFullYear();

  // console.log("Loaded:", imageLoaded);
  return (
    <div className={cardClass} onClick={() => openProject(props.data.id)}>
      <div className="projectCard__imageContainer">
        <img
          src={props.data.heroImage}
          alt="project"
          onLoad={() => setImageLoaded(true)}
          hidden={imageLoaded ? false : true}
        ></img>
        {!imageLoaded ? (
          <div className="loader">
            <img src={Images.logo} alt="loading"></img>
          </div>
        ) : (
          ""
        )}
        {/* {props.small && <div className="overlay"></div>} */}
      </div>
      <div className="projectCard__descContainer">
        <h4 className="projectName">{props.data.name}</h4>
        {props.small && (
          <p
            className="projectshortDesc"
            dangerouslySetInnerHTML={{
              __html: `${props.data.description}`,
            }}
          ></p>
        )}
        {(props.data.categories || props.data.dateCreated) && (
          <>
            {!props.small && props.data.dateCreated && (
              <div className="projectCategoryDate">
                <h5 className="category">{props.data.categories[0]}</h5>

                <h5 className="date">{`${projectMonth} ${projectYear}`}</h5>
              </div>
            )}
          </>
        )}
        {!props.small && (
          <p
            className="projectshortDesc"
            dangerouslySetInnerHTML={{
              __html: `${props.data.description}`,
            }}
          ></p>
        )}
        {props.small && props.data.dateCreated ? (
          <div className="projectCategoryDate">
            <h5 className="category">{props.data.categories[0]}</h5>

            <h5 className="date">{`${projectMonth} ${projectYear}`}</h5>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
