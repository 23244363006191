import React from "react";
// import { projectList } from "../../assets/projects/projectsData";
import { useParams } from "react-router-dom";
import ProjectCard from "../common/ProjectCard/ProjectCard";
import Icons from "../../assets/icons/icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import mixpanel from "mixpanel-browser";
import axios from "axios";

const ProjectPreviewPage = (props) => {
  let navigate = useNavigate();
  mixpanel.init("a12ca9a99d7c4a1c7308e83c538581a6");

  let params = useParams();
  // console.log("NEW ID:", params.id);
  var PID = params.id;

  // const [projectID, setProjectID] = useState(params.id);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [projectData, setProjectData] = useState({});
  const [projectList, setProjectList] = useState(props.allProjects);
  // const [dataReady, setDataReady] = useState(false);
  var months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  //   console.log(params.id);

  // const projectData = projectList.find(
  //   (project) => String(project.pid) === params.id
  // );
  //   console.log(projectData);
  //   var current_index = 0;

  var similarProjects = [];
  var carouselImageData = [];
  var carouselVideoData = [];
  var carouselYoutubeVideoData = [];
  var projectMonth;
  var projectYear;
  if (Object.keys(projectData).length) {
    similarProjects = projectList.filter(
      (project) =>
        project.technologies.includes(projectData.technologies[0]) ||
        project.tools.includes(projectData.tools[0])
    );

    similarProjects = similarProjects.filter((project) => {
      // console.log(project.id, projectData.id);
      if (project.id !== projectData.id) {
        return true;
      } else {
        return false;
      }
    });
    carouselImageData = projectData.carouselImages.map((image, key) => {
      return <img key={key} src={image} alt="project"></img>;
    });
    carouselVideoData = projectData.carouselVideos.map((video, key) => {
      return <video key={key} src={video} controls></video>;
    });

    carouselYoutubeVideoData = projectData.carouselYoutubeVideos.map(
      (videoLink, key) => {
        return (
          <iframe
            width="560"
            height="315"
            src={videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      }
    );

    projectMonth = months[new Date(projectData.dateCreated).getMonth()];
    projectYear = new Date(projectData.dateCreated).getFullYear();
  }

  const carouselData = [
    ...carouselImageData,
    ...carouselVideoData,
    ...carouselYoutubeVideoData,
  ];
  //   const carousel_imageData = projectData.carousel_images[carouselIndex];
  // console.log("CD:", carouselIndex, carouselData);

  const showNextSlide = () => {
    if (carouselIndex === carouselData.length - 1) {
      setCarouselIndex(0);
    } else {
      setCarouselIndex(carouselIndex + 1);
    }
  };
  const showPrevSlide = () => {
    if (carouselIndex === 0) {
      setCarouselIndex(carouselData.length - 1);
    } else {
      setCarouselIndex(carouselIndex - 1);
    }
  };
  const showSlide = (number) => {
    setCarouselIndex(number);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    axios.get("/projects/all").then((response) => {
      setProjectList(response.data);
      const validData = response.data.find(
        (project) => String(project.id) === PID
      );
      if (!validData) {
        navigate("/makes", { replace: true });
      } else {
        axios.get(`/projects/?id=${PID}`).then((response) => {
          setProjectData(response.data);
          document.title = "Make - " + String(response.data.name);
          mixpanel.track("Project Page Views", {
            name: response.data.name,
          });
          // window.scrollTo(0, 0);
        });
      }
    });
    // eslint-disable-next-line
  }, [PID]);

  // console.log("PID:", projectID);

  return (
    <>
      <div className="ProjectPreviewPage pageContainer">
        {carouselImageData.length ? (
          <div className="ProjectPreviewPage__content pageContent">
            <div className="ProjectPreviewPage__projectInfo">
              <div className="leftContainer">
                <div className="carousel">
                  <div className="carousel__slide">
                    {carouselData[carouselIndex]}
                  </div>
                  <div className="carousel__indicators">
                    {carouselData.map((item, key) => {
                      return (
                        <div
                          className={
                            carouselIndex === key ? "dot dot--active" : "dot"
                          }
                          key={key}
                          onClick={() => showSlide(key)}
                        ></div>
                      );
                    })}
                  </div>
                  <div className="carousel__controls prevButton">
                    <img
                      src={Icons.prevIcon}
                      alt="prev"
                      onClick={showPrevSlide}
                    />
                  </div>
                  <div className="carousel__controls nextButton">
                    <img
                      src={Icons.nextIcon}
                      alt="next"
                      onClick={showNextSlide}
                    />
                  </div>
                </div>

                <div className="projectDescContainer">
                  <div className="projectCategoryDate">
                    <h3>
                      {projectData.categories[0]} |{" "}
                      {`${projectMonth} ${projectYear}`}
                    </h3>
                  </div>
                  <h2 className="projectTitle">{projectData.name}</h2>
                  <h3
                    className="projectDescription"
                    dangerouslySetInnerHTML={{
                      __html: `${projectData.description}`,
                    }}
                  ></h3>
                </div>
              </div>
              <div className="rightContainer">
                {projectData.instagramLink ||
                projectData.instructablesLink ||
                projectData.githubLink ||
                projectData.patreonLink ? (
                  <div className="projectLinks">
                    <h2 className="heading">Checkout this project on</h2>

                    {projectData.instructableLink && (
                      <a
                        className="linkButton"
                        href={projectData.instructablesLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={Icons.instructablesCIcon}
                          alt="intructables"
                        ></img>
                        <span>View On Instructables</span>
                      </a>
                    )}

                    {projectData.instagramLink && (
                      <a
                        className="linkButton"
                        href={projectData.instagramLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Icons.instagramCIcon} alt="instagram"></img>
                        <span>View On Instagram</span>
                      </a>
                    )}
                    {projectData.youtubeLink && (
                      <a
                        className="linkButton"
                        href={projectData.youtubeLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Icons.youtubeCIcon} alt="youtube"></img>
                        <span>View On Youtube</span>
                      </a>
                    )}
                    {projectData.githubLink && (
                      <a
                        className="linkButton"
                        href={projectData.githubLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Icons.githubCIcon} alt="github"></img>
                        <span>View On GitHub</span>
                      </a>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {projectData.tools?.length ||
                projectData.technologies?.length ||
                projectData.collaborators?.length ||
                projectData.duration ? (
                  <div className="projectResources">
                    <h2 className="heading">Resources &amp; Info</h2>
                    <div className="resourcesContent">
                      {projectData.tools?.length ? (
                        <div className="resourcesContent__desc">
                          <h3>Tools</h3>
                          <h4>{projectData.tools.join(", ")}</h4>
                        </div>
                      ) : (
                        ""
                      )}
                      {projectData.duration ? (
                        <div className="resourcesContent__desc">
                          <h3>Duration</h3>
                          <h4>{projectData.duration}</h4>
                        </div>
                      ) : (
                        ""
                      )}
                      {projectData.technologies?.length ? (
                        <div className="resourcesContent__desc">
                          <h3>Technologies</h3>
                          <h4>{projectData.technologies.join(", ")}</h4>
                        </div>
                      ) : (
                        ""
                      )}
                      {projectData.collaborators?.length ? (
                        <div className="resourcesContent__desc">
                          <h3>Collaborators</h3>
                          <h4>{projectData.collaborators.join(", ")}</h4>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {projectData.otherLinks?.length ? (
                  <div className="otherLinks">
                    <h3 className="heading">Other Reference Links</h3>
                    {projectData.otherLinks.map((item, key) => {
                      return (
                        <div key={key}>
                          <h3 className="linkHeading">{item.title}</h3>
                          <a
                            className="link"
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.link}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {similarProjects?.length ? (
              <div className="ProjectPreviewPage__similarProjects">
                <h1 className="heading">Similar Projects</h1>
                <div className="projectsList">
                  {similarProjects.map((project, key) => {
                    return (
                      <ProjectCard key={key} small={true} data={project} />
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ProjectPreviewPage;
