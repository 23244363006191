import axios from "axios";
import React, { useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Icons from "../../assets/icons/icons";
import Images from "../../assets/images/images";

const NewsLetterForm = (props) => {
  const email = useRef("");
  const name = useRef("");
  // eslint-disable-next-line
  const [subscribeCookieVal, setSubscribeCookieVal] = useCookies([
    "mv_subscribe",
  ]);

  // console.log(subscribeCookieVal.mv_subscribe);

  const [subscribeDisabled, setSubscribeDisabled] = useState(true);

  const submitForm = (event) => {
    event.preventDefault();
    // console.log(email.current.value, name.current.value);
    const data = { email: email.current.value, name: name.current.value };
    axios
      .post("/newsletter/subscribe", data, {})
      .then((response) => {
        // console.log("DATA:", response.data);
        let d = new Date();
        d.setFullYear(d.getFullYear() + 1);
        setSubscribeCookieVal("mv_subscribe", true, { path: "/", expires: d });
        props.closeModal();
      })
      .catch((error) => {
        // props.closeModal();
        // console.log("ERROR:", error);
      });
  };

  const closeModal = (event) => {
    event.preventDefault();
    props.closeModal();
  };

  const validateEmailPattern = (emailText) => {
    return emailText
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateEmail = () => {
    if (
      validateEmailPattern(email.current.value) &&
      name.current.value.length
    ) {
      setSubscribeDisabled(false);
    } else {
      setSubscribeDisabled(true);
    }
  };

  const validateName = () => {
    if (
      validateEmailPattern(email.current.value) &&
      name.current.value.length
    ) {
      setSubscribeDisabled(false);
    } else {
      setSubscribeDisabled(true);
    }
  };

  return (
    <>
      <form
        className="NewsLetterForm"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="NewsLetterForm__container--left">
          <h2 className="heading">Maker Vinod</h2>
          <h3 className="sub-heading">Newsletter</h3>
          <div className="imageContainer">
            <img className="heroImage" src={Images.myImage} alt="vinod" />
          </div>
        </div>
        <div className="NewsLetterForm__container--right">
          <h3 className="sub-heading">
            Make Projects with <span className="highLighted">Maker Vinod</span>
          </h3>
          <p className="descriptionText">
            Subscribe to the newsletter to get regular project updates, &
            information about new exepreiments
          </p>
          <div className="form-fields">
            <label htmlFor="email">Email-ID</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email-id"
              ref={email}
              required
              onInput={validateEmail}
            />
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Enter your name"
              ref={name}
              required
              onInput={validateName}
            />
            <button
              type="submit"
              onClick={submitForm}
              disabled={subscribeDisabled}
            >
              Subscribe
            </button>
          </div>
        </div>
        {/* <button className="closeButton" onClick={closeModal}></button> */}
        <img
          className="closeButton"
          src={Icons.closeIcon}
          onClick={closeModal}
          alt="close"
        ></img>
      </form>
    </>
  );
};

export default NewsLetterForm;
