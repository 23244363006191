import React, { useEffect } from "react";
import Icons from "../../assets/icons/icons";
import { useCookies } from "react-cookie";

const CookiesPopup = (props) => {
  // eslint-disable-next-line
  const [cookiesVal, setCookieVal] = useCookies(["mv_user"]);
  useEffect(() => {
    let d = new Date();
    d.setFullYear(d.getFullYear() + 1);
    setCookieVal("mv_user", true, { path: "/", expires: d });
    // eslint-disable-next-line
  }, []);

  // console.log("popo", cookiesVal.mv_user);

  return (
    <div className="CookiesPopup">
      <img
        className="closeButton"
        src={Icons.closeIcon}
        onClick={props.onClose}
        alt="close"
      ></img>
      <div className="CookiesPopup__content">
        <div className="header">
          <img src={Icons.cookiesIcon} alt="cookies"></img>
          <h3>
            This website uses cookies to improve user experience. By using this
            website you accept all cookies.
          </h3>
        </div>
        {/* <div className="controls">
          <button>Accept &amp; Close</button>
        </div> */}
      </div>
    </div>
  );
};

export default CookiesPopup;
