import React from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { portfolioProjects } from "../../../assets/portfolio/portfolioProjectsData";
const IdCheckWrapper = (props) => {
  let params = useParams();

  const type = props.type;
  var validData = [];
  if (type === "makes") {
    validData = props.allProjects.find(
      (project) => String(project.uuid) === params.id
    );
    if (!validData) {
      return <Navigate to="/makes" />;
    }
  } else {
    if (type === "project") {
      validData = portfolioProjects.find(
        (project) => String(project.pid) === params.id
      );
      if (!validData) {
        return <Navigate to="/portfolio" />;
      }
    } else {
      return <Navigate to="/makes" />;
    }
  }

  return <>{props.children}</>;
};

export default IdCheckWrapper;
