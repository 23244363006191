export const portfolioProjects = [
  {
    pid: 1,
    cover_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/EmbedUI/embedui_anim_cover.webp",
    hero_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/EmbedUI/embedui_cover.png",
    name: "EmbedUI",
    technologies: ["NodeJS", "Electron", "HTML", "CSS", "JS", "Embedded C/C++"],
    type: "Side Project",
    category: "Raspberry Pi",
    date: "Feb 2021",
    description_short: `EmbedUI is an open-source Project for enthusiasts, to speed up & visualize UI development using Embedded Hardware on their PC/Laptop screens.`,
    description_long: `EmbedUI is a cross-platform application and an embedded library which enables the user to produce User interfaces for their Embedded projects without any additional display hardware.<br>The <a style="color: #008184;text-decoration: none;font-weight: 700;" href="https://github.com/EmbedUI/EmbedUI-App" target="_blank">Web app</a> is built using <strong>Electron Framework</strong> and the <a style="color: #008184;text-decoration: none;font-weight: 700;"        href="https://www.arduinolibraries.info/libraries/embed-ui" target="_blank">C/C++ library</a> on the Embedded part to draw UI elements in the Desktop App. <br><br><a onMouseOver="this.style.opacity=0.7" onMouseOut="this.style.opacity=1" style="color: white;font-family:'Space Grotesk',sans-serif;padding: 0.8rem 1.5rem;background-color: #132743;border-radius: 8px;text-decoration: none;" href="https://github.com/EmbedUI" target="_blank">View on GitHub</a>`,
    challenges: `I came across the problem of searching for an LCD while tinkering for my hobby projects. Making UI for embedded devices has an external LCD dependency and requires extensive initial time to interface LCD with development boards using different protocols.<br><br>Challenge was to create a solution to quickly prototype UI applications for embedded boards and remove the external dependency of the high-end development boards and LCD.`,
    challenge_images: [],
    nda: false,
    year: "2021",
    role: "Fullstack Developer",
    work: [
      "Developed Frontend",
      "Developed Backend",
      "Developed Hardware Library",
    ],
    solution: `<strong>HTML</strong> being an efficient & fast language to develop UI elements was selected as the base for this project. The Frontend was developed using HTML. Devednya Vyas designed the UI for the Application. <strong>Figma</strong> was used as a design platform. We spent a week finalizing the UI flow for the application.  The complete application was built using <strong>Electron Framework</strong> running on NodeJS. <br><br>The user can connect the hardware to the application using the serial communication port. I also developed a <strong>C/C++ library</strong> for the hardware to support the communication protocol of the application. The commands used for drawing UI elements were single-line string commands, which could be easily related to generic display libraries available. This made the hardware library easier to understand & use. <br><br>We also added few examples for the user to understand how to use the library & the application.  Support for <strong>Auto-in-App Updates</strong> was enabled to provide a hassle-free Application Update feature for future releases. We conducted a <strong>usability test</strong> with individuals during the development stages and asked them to share their feedback. This helped us much to find a way to get the application running up in a user-friendly manner.`,
    solution_images: [
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/EmbedUI/ui_view.gif",
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/EmbedUI/examples.gif",
    ],
    learnings: `• We worked as a team of two handling and understanding different tasks. As a part of a team, I learned a thing or two about <strong>UX, making user-friendly products, understanding, and building solutions focused on users</strong>.<br>
• This being my first <strong>open-source project</strong>, I was exposed to the creation of an open-source library. I successfully followed the <strong>Arduino</strong> library-creation instructions and published the library in the widely used Arduino IDE Library Manager.<br>
• I extensively worked on Electron Framework, focusing on various aspects such as <strong>Front-end user interactions, communication between front-end & back-end interfaces using the Inter-process communication (IPC) module</strong>. I also learned about implementing an auto-update feature using <strong>GitHub releases</strong> to streamline version controlling and hassle-free application updates. <br>
• I developed a customized protocol for communication between the hardware & the application which allowed <strong>JavaScript-based dynamic HTML elements creation & deletion from DOM</strong> through the hardware.`,
    learnings_image: [],
    external_links: [
      {
        title: "EmbedUI Official Website",
        link: "https://embedui.github.io",
      },
    ],
  },
  {
    pid: 2,
    cover_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/RangeControllerSystem/range_controller_new.png",
    hero_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/RangeControllerSystem/range_controller_new.png",

    name: "Range Controller System",
    technologies: ["(Under NDA)"],
    type: "NDA",
    category: "",
    date: "Feb 2021",
    description_short: `A product to upgrade shooting ranges using modern technologies & automation, that enhances customer experiences.`,
    description_long: `A project to upgrade shooting ranges using modern technologies & automation that enhances customer experiences.`,
    challenges: ``,
    challenge_images: [],
    nda: true,
    year: "2021",
    role: "Fullstack Developer",
    work: ["Developed Frontend", "Developed Backend", "Developed Hardware"],
    solution: ``,
    solution_images: [],
    learnings: ``,
    learnings_image: "",
    external_links: [],
  },
  {
    pid: 3,
    cover_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/IDEAZZZZ_DUP/dup_demo.webp",
    hero_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/IDEAZZZZ_DUP/dup_demo.webp",

    name: "IDEAZZZZ DUP",
    technologies: ["(Under NDA)"],
    type: "NDA",
    category: "",
    date: "Feb 2021",
    description_short: `A Realistic 3D Body Scanning Setup​​​​​`,
    description_long: `A Realistic 3D Body Scanning Setup​​​​​`,
    challenges: ``,
    challenge_images: [],
    nda: true,
    year: "2021",
    role: "Backend Developer",
    work: ["Developed Backend", "Developed Hardware"],
    solution: ``,
    solution_images: [],
    learnings: ``,
    learnings_image: "",
    external_links: [
      {
        title: "IDEAZZZZ DUP Official Link",
        link: "http://www.ideazzzz.com/ideazzzz-dup",
      },
    ],
  },
  {
    pid: 4,
    cover_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/MoxieSkills/mixed_moxie.webp",
    hero_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/MoxieSkills/mixed_moxie.webp",
    name: "Moxie Skills",
    technologies: ["Python", "Android", "Embedded C/C++"],
    type: "Product",
    category: "",
    date: "Feb 2021",
    description_short: `Moxie Skills designs and develops equipment to help football coaches to train players effectively using smart technology-enabled devices. `,
    description_long: `Moxie Skills designs and develops equipment to help football coaches to train players effectively using <strong>smart technology-enabled devices</strong>.<br> This project was a collaborative approach, focusing on the aspects of using technology for <strong>Sports & Player Skill Improvements</strong>.<br>Due to the COVID-19 Pandemic, the project has been paused.`,
    challenges: `<strong>Football</strong>, being a sport played by the many, requires great practice to improve the skills of a player. Thinking of an interactive & smart way to do this, we planned to design a device that can help improve the skills of a player through practice. The project was cut down to different aspects, <strong>software, embedded hardware & structure design</strong>, being the crucial ones. Making the system easy to use, control and assemble in the field was the most challenging task. The device was required to be durable, portable & modular, which increased the challenges for us.`,
    challenge_images: [
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/MoxieSkills/player.webp",
    ],
    nda: false,
    year: "2019",
    role: "Software Developer",
    work: [
      "Developed Android Application",
      "Develop Backend",
      "Developed Hardware Devices",
    ],
    solution: `We started our first prototype with a single Aluminum Sheet targeting durability & low weight but was a bit costly. We finalized the hardware setup & sensors for counting football hits, that were battery-powered. On the very first try with the structure, we failed as the structure merely sustained a single football kick. Later after few more iterations, keeping control of our budgets for prototyping, we moved on to a new option of using <strong>highly-compressed waterproof wood sheets</strong>, placed in a circular manner, to bear the hitting force. And this did work. The system required more stability, for which we introduced metal supports behind the wood sheets. The system had a central server running on <strong>Linux System</strong>, which was responsible to handle the communication & feedback between the sensing devices & the Android Application. The coach was able to set the required settings for the drill, using the Application & monitor the live status of the drill being played.`,
    solution_images: [
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/MoxieSkills/player.webp",
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/MoxieSkills/moxie_app.webp",
    ],
    learnings: `• This was my first project in sports technology. I handled the development of the <strong>Backend application & the Android Application</strong>.<br>• The central server application was built using <strong>Python & MQTT</strong> running over a <strong>Raspberry Pi</strong>.<br>• This project helped me improve my Android Application Development skills by developing the required application.<br>• Learned to utilize Figma for developing frontend applications.`,
    learnings_image: [],
    external_links: [],
  },
  {
    pid: 5,
    cover_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/COVID-19/covid_dashboard_cover.png",
    hero_image:
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/COVID-19/covid_dashboard_cover.png",

    name: "COVID-19 Dashboard & API",
    technologies: ["Python", "Django", "HTML", "CSS", "JS", "Embedded C/C++"],
    type: "Side Project",
    category: "Raspberry Pi",
    date: "Feb 2021",
    description_short: `A web application that provides the live statistics of the current COVID-19 conditions of a country in terms of figures & graphical data about the Active, Recovered & Deceased COVID-19 Cases. Provides API for web & Embedded applications.`,
    description_long: `COVID-19 Dashboard is a web application to report the live status of the pandemic in a country. The data is fetched from RapidAPI and visualized over a webpage in numerical & graphical format. COVID-19 Dashboard also provides an API interface that enables users to access the manipulated data through targeted endpoints.`,
    challenges: `Back in 2020, when the COVID-19 cases shot up, I was very keen on monitoring the live COVID-19 situation in my country (India).<br><br>The Challenge was to create a monitoring device that had no dependency on a TV, PC, or Cellphone to get the status. Making COVID-19 data available to other developers was the second challenge of this project.`,
    challenge_images: [
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/COVID-19/covid_dashboard.png",
    ],
    nda: false,
    year: "2020",
    role: "Fullstack Developer",
    work: ["Developed Frontend", "Developed Backend"],
    solution: `I developed a web application that could run on an online server to handle the target application. I selected Django Framework to build the web application that was hosted on Google Cloud Platform (using the free tier credits). Also,  I worked on a supportive API for Embedded hardware and a small display that could be left ON all day, consuming an extremely low amount of power.<br><br>Dashboard application contained a responsive web page to view the live status of the situation on any device. The frontend was built using Bootstrap.<br><br>The Dashboard Application also contained an API interface In order, to allow users to access the data through endpoints. Users were allowed to access the data through the API endpoints using the user-specific auth-keys that were listed in their respective accounts. A lot of users from all over the world utilized the API for their respective applications.`,
    solution_images: [
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/COVID-19/api_interface.gif",
      "https://storage.googleapis.com/makervinod-website-assets/Portfolio-Projects/COVID-19/user_records.png",
    ],
    learnings:
      "• I explored the various aspects of user account creation and authentication & account management.<br>• I also learned about setting up a Django Application on a Compute Server (Google Cloud Platform) in production mode.<br>• Similarly, using Bootstrap, Jinja Template Engine, handling API requests & monitoring API requests were some more key takeaways for me.",
    learnings_image: "",
    external_links: [
      {
        title: "Instructables Project",
        link: "https://www.instructables.com/COVID-19-Dashboard-Simple-Easy/",
      },
    ],
  },
];
