import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../../assets/images/images";
import mixpanel from "mixpanel-browser";

const HomePage = () => {
  mixpanel.init("a12ca9a99d7c4a1c7308e83c538581a6");
  useEffect(() => {
    document.title = "Maker Vinod";
    window.scrollTo(0, 0);
    mixpanel.track("Homepage Views");
  });
  return (
    <>
      <HomePageContainer />
    </>
  );
};

export const HomePageContainer = () => {
  const d = new Date();
  // console.log(d.getTime());
  const [makerText, setMakerText] = useState("");

  // eslint-disable-next-line
  const [startTime, setStartTime] = useState(d.getTime());
  // const [iterations, setIterations] = useState(0);

  useEffect(() => {
    const finalTextHandler = setTimeout(() => {
      setMakerText("Maker.");
    }, 1450);
    return () => {
      clearTimeout(finalTextHandler);
    };
  }, []);

  useEffect(() => {
    document.title = "Maker Vinod";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charactersLength = characters.length;

    const iterationsHandler = setTimeout(() => {
      const d = new Date();
      var timeDiff = d.getTime() - startTime;
      if (timeDiff < 1450) {
        var letter1 =
          timeDiff < 600
            ? characters.charAt(Math.floor(Math.random() * charactersLength))
            : "M";
        var letter2 =
          timeDiff < 800
            ? characters.charAt(Math.floor(Math.random() * charactersLength))
            : "A";
        var letter3 =
          timeDiff < 1000
            ? characters.charAt(Math.floor(Math.random() * charactersLength))
            : "K";
        var letter4 =
          timeDiff < 1200
            ? characters.charAt(Math.floor(Math.random() * charactersLength))
            : "E";
        var letter5 =
          timeDiff < 1400
            ? characters.charAt(Math.floor(Math.random() * charactersLength))
            : "R";
        // setIterations(iterations + 1);
        // console.log(startTime, timeDiff);

        setMakerText(
          letter1 +
            letter2.toLowerCase() +
            letter3.toLowerCase() +
            letter4.toLowerCase() +
            letter5.toLowerCase()
        );
      }
    }, 10);
    return () => {
      clearTimeout(iterationsHandler);
    };
    // eslint-disable-next-line
  }, [makerText]);

  const navigate = useNavigate();

  return (
    <div className="HomePage pageContainer">
      <div className="HomePage__content pageContent">
        <div className="HomePage__contentAbout">
          <h1 className="heroText">
            Hi, I am Vinod.
            <br />I am a {makerText}
          </h1>
          <p className="descText">
            I am passionate about making things, experimenting with new
            technology &amp; happily baking and sharing things through{" "}
            <a
              className="tagName"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/maker.vinod"
            >
              @maker.vinod
            </a>
          </p>

          <button className="" onClick={() => navigate("/makes")}>
            Checkout My Makes
          </button>
        </div>
        <div className="HomePage__contentGraphics">
          <img className="heroImage" src={Images.myImage} alt="vinod"></img>
          <div className="imageRotator">
            {/* <img src={Images.model_3d}></img>
            <img src={Images.codeSnap}></img>
            <img src={Images.pcb}></img> */}
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
