import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "../HomePage/HomePage";
import ProjectsPage from "../ProjectsPage/ProjectsPage";
import Portfolio from "../Portfolio/Portfolio";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import ProjectPreviewPage from "../ProjectPreviewPage/ProjectPreviewPage";
import PortfolioProjectPreviewPage from "../PortfolioProjectPreviewPage/PortfolioProjectPreviewPage";
import CookiesPopup from "../CookiesPopup/CookiesPopup";
import { useCookies } from "react-cookie";
import IdCheckWrapper from "../hoc/IdCheckWrapper/IdCheckWrapper";
// import GiveAwaysPage from "../GiveAwaysPage/GiveAwaysPage";
import Modal from "../hoc/Modal/Modal";
import NewsLetterForm from "../NewsLetterForm/NewsLetterForm";
import { useEffect } from "react";

const Layout = (props) => {
  // eslint-disable-next-line
  const [cookiesVal, setCookieVal] = useCookies(["mv_user"]);
  // eslint-disable-next-line
  const [subscribeCookieVal, setSubscribeCookieVal] = useCookies([
    "mv_subscribe",
  ]);
  const [hidePopup, setHidePopup] = useState(cookiesVal.mv_user || false);
  const [newsLetterFormVisible, setNewsLetterFormVisible] = useState(false);

  useEffect(() => {
    if (hidePopup) {
      setTimeout(() => {
        setNewsLetterFormVisible(true);
      }, 3000);
    }
    // eslint-disable-next-line
  }, []);

  const triggerNewsLetterForm = () => {
    setTimeout(() => {
      setNewsLetterFormVisible(true);
    }, 5000);
  };

  // console.log("Updated:", cookiesVal);
  return (
    <>
      {/* {cookiesVal.mv_user === "true" || hidePopup ? (
        ""
      ) : (
        <CookiesPopup onClose={() => setHidePopup(true)} />
      )} */}
      {!hidePopup && (
        <CookiesPopup
          onClose={() => {
            setHidePopup(true);
            triggerNewsLetterForm();
          }}
        />
      )}
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route
          path="/portfolio/project/:id"
          element={
            <IdCheckWrapper type="project">
              <PortfolioProjectPreviewPage />
            </IdCheckWrapper>
          }
        ></Route>
        <Route path="/portfolio/project/" element={<Portfolio />}></Route>
        <Route
          path="/makes/:id"
          element={
            // <IdCheckWrapper type="makes">
            <ProjectPreviewPage />
            // </IdCheckWrapper>
          }
        />
        <Route path="/makes" element={<ProjectsPage />}></Route>
        {/* <Route path="/giveaways" element={<GiveAwaysPage />}></Route> */}
        <Route path="*" element={<Navigate to="/" replace />}></Route>
      </Routes>
      <Footer />
      {hidePopup && newsLetterFormVisible && !subscribeCookieVal.mv_subscribe && (
        <Modal onClick={() => setNewsLetterFormVisible(false)}>
          <NewsLetterForm closeModal={() => setNewsLetterFormVisible(false)} />
        </Modal>
      )}
    </>
  );
};

export default Layout;
