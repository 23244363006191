const Icons = {
  githubIcon: require("./githubIcon.svg").default,
  instructablesIcon: require("./instructablesIcon.svg").default,
  instagramIcon: require("./instagramIcon.svg").default,
  linkedInIcon: require("./linkedInIcon.svg").default,
  prevIcon: require("./prev.svg").default,
  nextIcon: require("./next.svg").default,
  githubCIcon: require("./github.svg").default,
  instructablesCIcon: require("./instructables_icon.svg").default,
  instagramCIcon: require("./instagram.svg").default,
  youtubeCIcon: require("./youtube.svg").default,
  cookiesIcon: require("./cookies_icon.svg").default,
  closeIcon: require("./closeButton.svg").default,
};

module.exports = Icons;
