import React, { useEffect } from "react";
import { portfolioProjects } from "../../assets/portfolio/portfolioProjectsData";
import Tilt from "react-parallax-tilt";
import { useNavigate } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const Portfolio = () => {
  mixpanel.init("a12ca9a99d7c4a1c7308e83c538581a6");

  useEffect(() => {
    document.title = "Maker Vinod - Portfolio";
    window.scrollTo(0, 0);
    mixpanel.track("Portfolio Views");
  });
  return (
    <div className="Portfolio pageContainer">
      <div className="Portfolio__content pageContent">
        <div className="Portfolio__projectsList">
          {portfolioProjects.map((project, key) => {
            // console.log(project);
            return <PortfolioProjectCard key={key} data={project} />;
          })}
        </div>
      </div>
    </div>
  );
};

export const PortfolioProjectCard = ({ data }) => {
  let navigate = useNavigate();
  // const cardClass = props.small ? "projectCard smallCard" : "projectCard";
  const openProject = (pid) => {
    navigate("/portfolio/project/" + pid);
  };

  return (
    <div className="PortfolioProjectCard" onClick={() => openProject(data.pid)}>
      <Tilt
        tiltMaxAngleX="3"
        tiltMaxAngleY="3"
        glareEnable={true}
        glareMaxOpacity="0.2"
        scale="1.02"
      >
        <img
          className="projectCover"
          src={data.cover_image}
          alt="project-cover"
        ></img>
        <h2 className="projectName">{data.name}</h2>
        <h3 className="projectTechnologies">{data.technologies.join(", ")}</h3>
        <p className="projectShortDesc">{data.description_short}</p>
      </Tilt>
    </div>
  );
};

export default Portfolio;
