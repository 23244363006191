import React, { useState } from "react";
import Images from "../../assets/images/images";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [burgerOpened, setBurgerOpened] = useState(false);
  return (
    <div className="Navbar">
      <div className="Navbar__container">
        <Link
          className="Navbar__logoContainer"
          to="/"
          onClick={() => {
            setBurgerOpened(false);
          }}
        >
          <img src={Images.logo} alt="logoIcon"></img>
        </Link>
        <div className="Navbar__menuContainer">
          <NavbarMenu
            opened={burgerOpened}
            closeNavBar={() => {
              setBurgerOpened(false);
            }}
          />
          <div
            className={burgerOpened ? "hamBurger hamBurger--open" : "hamBurger"}
            onClick={() => setBurgerOpened(!burgerOpened)}
          ></div>
        </div>
      </div>
    </div>
  );
};

const NavbarMenu = (props) => {
  const style = props.opened ? { height: "11rem" } : { height: "0rem" };
  return (
    <ul className="NavbarMenu" style={style} onClick={props.closeNavBar}>
      <li className="NavbarMenu__item">
        <Link className="itemText" to="/makes">
          Makes
        </Link>
      </li>
      {/* <li className="NavbarMenu__item ">
        <Link to="/portfolio">Portfolio</Link>
      </li> */}
      {/* <li className="NavbarMenu__item ">
        <Link to="/giveaways">Giveaways</Link>
      </li> */}

      <li className="NavbarMenu__item tagName">
        <a
          target="_blank"
          href="https://www.instagram.com/maker.vinod?ref=makervinod"
          rel="noreferrer"
        >
          @maker.vinod
        </a>
      </li>
    </ul>
  );
};

export default Navbar;
