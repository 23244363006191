import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { portfolioProjects } from "../../assets/portfolio/portfolioProjectsData";
import mixpanel from "mixpanel-browser";

const PortfolioProjectPreviewPage = () => {
  mixpanel.init("a12ca9a99d7c4a1c7308e83c538581a6");

  let params = useParams();
  // console.log(params.id);
  const projectData = portfolioProjects.find(
    (project) => String(project.pid) === params.id
  );
  useEffect(() => {
    document.title = "Project - " + String(projectData.name);
    window.scrollTo(0, 0);
    mixpanel.track("Portfolio Project Views", {
      name: projectData.name,
    });
  });
  return (
    // <div className="PortfolioProjectPreviewPage">
    <div className="PortfolioProjectPreviewPage pageContainer">
      <div className="PortfolioProjectPreviewPage__content pageContent">
        <div className="projectInfo">
          <div className="projectInfo__content">
            <h2 className="projectName">{projectData.name}</h2>
            <h3 className="projectRoleYear">
              {projectData.role + " • " + projectData.year}
            </h3>
            <p
              className="projectDescription"
              dangerouslySetInnerHTML={{
                __html: `${projectData.description_long}`,
              }}
            ></p>
          </div>
          <div className="projectInfo__heroImage">
            <img src={projectData.hero_image} alt="project"></img>
          </div>
        </div>
        <div className="projectChallenge">
          {projectData.solution ? (
            <>
              <h1 className="heading">The Challenge</h1>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: `${projectData.challenges}`,
                }}
              ></p>
            </>
          ) : (
            ""
          )}
          {projectData.challenge_images.map((image, key) => {
            return <img src={image} alt={projectData.name} key={key} />;
          })}
          {projectData.solution ? (
            <>
              <h1 className="heading">The Solution</h1>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: `${projectData.solution}`,
                }}
              ></p>
            </>
          ) : (
            ""
          )}
          {projectData.solution_images.map((image, key) => {
            return <img src={image} alt={projectData.name} key={key} />;
          })}
          {projectData.learnings ? (
            <>
              <h1 className="heading">Learnings</h1>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: `${projectData.learnings}`,
                }}
              ></p>
            </>
          ) : (
            ""
          )}
          {projectData.work.length ||
          projectData.technologies.length ||
          projectData.external_links.length ? (
            <div className="summary">
              <h1 className="heading">Summary</h1>
              {projectData.work.length ? (
                <div className="summaryProp">
                  <h2 className="subHeading">WORK:</h2>
                  <ul>
                    {projectData.work.map((work, key) => {
                      return (
                        <li className="work" key={key}>
                          {work}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              {projectData.technologies.length ? (
                <div className="summaryProp">
                  <h2 className="subHeading">TECH STACK:</h2>
                  <ul>
                    {projectData.technologies.map((tech, key) => {
                      return (
                        <li className="tech" key={key}>
                          {tech}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
              {projectData.external_links.length ? (
                <div className="summaryProp">
                  <h2 className="subHeading">LINKS:</h2>
                  <ul>
                    {projectData.external_links.map((link, key) => {
                      return (
                        <li className="link" key={key}>
                          <a href={link.link} target="_blank" rel="noreferrer">
                            {link.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
    // </div>
  );
};

export default PortfolioProjectPreviewPage;
