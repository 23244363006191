import React from "react";
import Icons from "../../assets/icons/icons";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer__content">
        <div className="socialHandles">
          <h3 className="socialHandles__head">Find me on:</h3>
          <a
            className="socialHandles__icon"
            href="https://github.com/makervinod"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Icons.githubIcon} alt="githubIcon"></img>
          </a>
          <a
            className="socialHandles__icon"
            href="https://www.instagram.com/maker.vinod/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Icons.instagramIcon} alt="instagramIcon"></img>
          </a>
          <a
            className="socialHandles__icon"
            href="https://www.instructables.com/member/makervinod/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Icons.instructablesIcon} alt="instructablesIcon"></img>
          </a>
        </div>
        <div className="creditsCopyrights">
          <h3 className="creditsCopyrights__head">2022</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
